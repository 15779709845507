<template>
  <q-dialog ref="dialog" :maximized="fullscreen">
    <q-card style="display: flex; flex-direction: column; max-height: 100dvh">
      <q-card-section class="title-section">
        <q-icon name="fas fa-file-pdf" />
        <div class="text-h6">{{ title }}</div>
        <div style="flex-grow: 1"></div>
        <q-btn round flat @click="scale = Math.max(0.1, scale - 0.1)"><q-icon name="far fa-minus" size="1em" /></q-btn>
        <div class="scale">{{ (scale * 100).toFixed(0) }}%</div>
        <q-btn size="1em" round flat @click="scale += 0.1"><q-icon name="far fa-plus" size="1em" /></q-btn>
        <q-btn v-if="!fullscreen" round flat icon="far fa-expand" @click="fullscreen = true" />
        <q-btn v-else round flat icon="far fa-compress" @click="fullscreen = false" />
      </q-card-section>
      <q-card-section class="main-section">
        <div class="sidebar left">
          <div class="page-list-wrapper">
            <div
              v-for="(_, i) in new Array(document?.numPages).fill(true)"
              :key="i"
              :class="['page-thumbnail', { 'current-page': currentPage === i + 1 }]"
              @click="currentPage = i + 1"
            >
              <div :ref="renderThumbnail(i)" class="page-thumbnail-image">
                <q-spinner v-if="renderedThumbnails.get(i) !== 'rendered'" />
              </div>
              <div class="page-thumbnail-label">{{ i + 1 }} / {{ document?.numPages }}</div>
              <div v-if="matches[i] && matches[i].length > 0" class="page-match-badge">{{ $tt('pageMatches', { count: matches[i].length }) }}</div>
            </div>
          </div>
        </div>
        <transition name="top-bar">
          <div v-if="searchOpen" class="top-bar">
            <q-input v-model="search" autofocus placeholder="Hae dokumentista..." dense />
            <span v-if="matchCount > 0" class="match-count">{{ $tt('matchCount', { count: matchCount }) }}</span>
            <span v-else-if="search.length > 0" class="no-matches">{{ $tt('noMatches') }}</span>
            <div v-if="search.length > 0" class="match-buttons">
              <q-btn :disable="disablePrevMatchButton" size="sm" @click="prevMatch">{{ $tt('previousMatch') }}</q-btn>
              <q-btn :disable="disableNextMatchButton" size="sm" @click="nextMatch">{{ $tt('nextMatch') }}</q-btn>
            </div>
            <div style="flex-grow: 1" />
            <q-btn round size="0.75em" flat icon="fas fa-x" @click="closeSearch" />
          </div>
        </transition>
        <div class="content" @wheel="zoomHandler">
          <div class="page-wrapper">
            <div class="page" :style="{ '--scale-factor': scale }">
              <canvas ref="canvas" />
              <div ref="textLayer" class="textLayer"></div>
              <transition name="signature-appear">
                <div v-if="signature" class="signature pulse" :style="signatureStyles" @mousedown="onSignatureMouseDown">
                  <img :src="signature.image" draggable="false" @load="positionSignature" />
                </div>
              </transition>
              <div v-if="!pageRendered" class="page-spinner-container">
                <q-spinner />
              </div>
            </div>
          </div>
        </div>
        <transition name="sidebar-right">
          <div v-if="signature" class="sidebar right">
            <div class="text-h6">{{ $tt('signHeader') }}</div>
            <p>{{ $tt('signParagraph') }}</p>
            <div class="signature-buttons">
              <q-btn rounded @click="signPDF">{{ $tt('sign') }}</q-btn>
              <q-btn rounded @click="signature = undefined">{{ $tt('cancel') }}</q-btn>
            </div>
          </div>
        </transition>
      </q-card-section>
      <q-card-actions class="actions-section">
        <q-btn size="1em" rounded @click="searchOpen = true">
          <q-icon name="fas fa-search" size="1em" style="margin-right: 1em" />
          {{ $tt('search') }}
        </q-btn>
        <div style="width: 2em" />
        <q-btn v-if="$store.user != null && isSafeboxWritable" size="1em" rounded :disabled="signature" @click="startSigning">
          <q-icon name="fas fa-file-signature" size="1em" style="margin-right: 1em" />
          {{ $tt('sign') }}
          <q-tooltip anchor="top middle" self="bottom middle">{{ $tt('signToolTip') }}</q-tooltip>
        </q-btn>
        <q-btn size="1em" rounded @click="onDownload">
          <q-icon name="fas fa-download" size="1em" style="margin-right: 1em" />
          {{ $tt('download') }}
        </q-btn>
        <div style="flex-grow: 1" />
        <q-btn rounded @click="hide()">{{ $tt('close') }}</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { clamp, sortBy } from 'lodash-es'
import * as pdfjs from 'pdfjs-dist'
import { TextItem } from 'pdfjs-dist/types/src/display/api'
import { QDialog } from 'quasar'
import { toRaw } from 'vue'
import { Component, mixins, Prop, Watch } from 'vue-facing-decorator'

import { Notification, NotificationLevel } from '@/classes/Notification'
import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import Value from '@/classes/Value'
import { apiFetchJSON, downloadFile, fetchAndDecryptFile, fetchTuple, newTuple, updateTuple } from '@/utils/api-functions'
import { apiFetch } from '@/utils/api-functions-base'
import { arrayBufferToBase64 } from '@/utils/crypt'
import { apiPath } from '@/utils/paths'
import { insertFile } from '@/utils/safebox'
import { SafeboxMixin } from '@/utils/SafeboxMixin'
import Signer from '@/utils/signer'

// Polyfill, jonka PDF.js vaatii
if (typeof Promise.withResolvers === 'undefined') {
  // @ts-ignore-next
  Promise.withResolvers = function () {
    let resolve, reject
    // eslint-disable-next-line promise/param-names
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    return { promise, resolve, reject }
  }
}

type DocumentPoint = {
  item: number
  offset: number
}

type Match = {
  start: DocumentPoint
  end: DocumentPoint
}

type SignatureState = {
  image: string
  x: number
  y: number
}

@Component({})
export default class PDFViewer extends mixins(SafeboxMixin) {
  declare $refs: {
    canvas: HTMLCanvasElement
    textLayer: HTMLDivElement
    dialog: QDialog
    thumbnail: HTMLCanvasElement[]
    signature?: HTMLImageElement
  }

  @Prop({ type: String })
  url!: string

  @Prop({ type: String })
  title!: string

  @Prop({ type: Tuple })
  tuple!: Tuple

  @Prop({ type: Signer })
  signer!: Signer

  @Prop({ type: Value })
  value!: Value

  @Prop({ type: Number })
  page?: number

  signInProcess = false

  document?: pdfjs.PDFDocumentProxy

  currentPage: number = 1
  renderedThumbnails = new Map<number, 'rendering' | 'rendered'>()
  pageRendered: boolean = false
  pageRendering: boolean = false
  textDivs: HTMLElement[] = []

  matches: Match[][] = []
  matchSpans: HTMLSpanElement[][][] = []
  activeMatch: number | undefined = undefined
  search: string = ''
  searchOpen = false

  signature?: SignatureState

  fullscreen = false

  scale = 1

  get matchCount() {
    return this.matches.map(page => page.length).reduce((a, b) => a + b, 0)
  }

  getMatchSpans(matchIndex: number) {
    const match = this.matches.flatMap((matches, page) => matches.map((_, index) => ({ page, index })))[matchIndex]
    if (!match) return []
    return this.matchSpans[match.page][match.index]
  }

  @Watch('activeMatch')
  async onActiveMatchChange(activeMatchIndex: number | undefined, prevActiveMatchIndex: number | undefined) {
    if (prevActiveMatchIndex !== undefined) {
      this.getMatchSpans(prevActiveMatchIndex).forEach(span => span.classList.remove('active'))
    }

    if (activeMatchIndex === undefined) {
      return
    }

    this.updateCurrentMatchHighlight()

    const { page } = this.matches.flatMap((matches, page) => matches.map((_, index) => ({ page, index })))[activeMatchIndex]

    if (page !== this.currentPage - 1) {
      this.currentPage = page + 1
    }
  }

  updateCurrentMatchHighlight() {
    if (this.activeMatch === undefined) {
      return
    }

    this.getMatchSpans(this.activeMatch).forEach(span => span.classList.add('active'))
  }

  /**
   * Käy läpi PDF:n sivujen teksisisällöt ja etsii käyttäjän hakua vastaavat pätkät.
   * Jokaisen osuman alku- ja loppukohtia vastaavat tekstielementin indeksi ja
   * tekstielementin sisäinen offset tallennetaan matches-attribuuttiin, ryhmiteltynä
   * sivun mukaan.
   */
  @Watch('search')
  async onSearchChange(search: string) {
    const document = toRaw(this.document)

    if (!document) return

    const matches: Match[][] = new Array(document.numPages).fill(true).map(() => [])

    if (search.length === 0) {
      this.matches = matches
      this.highlightMatches()
      return
    }

    for (let i = 0; i < document.numPages; i++) {
      const page = await document.getPage(i + 1)
      const content = await page.getTextContent()
      let text = ''

      const items = content.items.filter((item): item is TextItem => 'str' in item)

      for (const item of items) {
        text += item.str

        if (item.hasEOL) {
          text += '\n'
        }
      }

      const findItem = (match: RegExpMatchArray) => {
        let item = 0
        let pos = 0

        if (match.index === undefined) return null

        while (item < items.length && pos + items[item].str.length < match.index) {
          pos += items[item].str.length

          if (items[item].hasEOL) pos += 1

          item += 1
        }

        if (item === items.length) return null

        const start = {
          item,
          offset: match.index - pos
        }

        while (item < items.length && pos + items[item].str.length < match.index + match[0].length) {
          pos += items[item].str.length

          if (items[item].hasEOL) pos += 1

          item += 1
        }

        if (item === items.length) return null

        const end = {
          item,
          offset: match.index + match[0].length - pos
        }

        return { start, end }
      }

      const escaped = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escaped, 'gi')
      let match

      while ((match = regex.exec(text)) !== null) {
        const item = findItem(match)

        if (!item) continue

        matches[i].push(item)
      }
    }

    this.matches = matches

    const newActiveMatch = this.matches
      .flatMap((matches, page) => matches.map(match => ({ page, match })))
      .findIndex(({ page }) => page === this.currentPage - 1)

    this.activeMatch = newActiveMatch >= 0 ? newActiveMatch : undefined

    this.highlightMatches()
  }

  renderThumbnail(index: number) {
    return async (el: HTMLDivElement) => {
      if (this.renderedThumbnails.has(index)) return

      if (!this.document) return

      this.renderedThumbnails.set(index, 'rendering')

      const canvas = document.createElement('canvas') as HTMLCanvasElement
      const page = await toRaw(this.document).getPage(index + 1)
      const viewport = page.getViewport({ scale: 1.0 })
      const resolution = 1
      canvas.width = viewport.width * resolution
      canvas.height = viewport.height * resolution
      const ctx = canvas.getContext('2d')!
      await page.render({
        canvasContext: ctx,
        viewport,
        transform: [resolution, 0, 0, resolution, 0, 0]
      }).promise

      canvas.toBlob(blob => {
        if (!blob) return

        const url = URL.createObjectURL(blob)
        el.style.backgroundImage = `url('${url}')`
        el.style.aspectRatio = (viewport.width / viewport.height).toString()

        this.renderedThumbnails.set(index, 'rendered')
      })
    }
  }

  @Watch('scale')
  async onScaleChange() {
    await this.renderPage()
  }

  @Watch('currentPage')
  async onPageChange() {
    await this.renderPage()
  }

  @Watch('document')
  async onDocumentChange() {
    await this.renderPage()
  }

  async renderPage() {
    if (!this.document || this.pageRendering) return

    try {
      this.pageRendering = true

      const page = await toRaw(this.document).getPage(this.currentPage)
      const viewport = page.getViewport({ scale: this.scale })
      const resolution = 2

      // Tämän metodin suoritus saattaa jatkua myös dialogin sulkemisen jälkeen,
      // joten meidän on oltava tarkkoja siitä, että elementit ovat edelleen
      // olemassa.
      if (!this.$refs.canvas) return
      this.$refs.canvas.width = viewport.width * resolution
      this.$refs.canvas.height = viewport.height * resolution
      this.$refs.canvas.style.width = `${viewport.width}px`
      this.$refs.canvas.style.height = `${viewport.height}px`
      const ctx = this.$refs.canvas.getContext('2d')!
      await page.render({
        canvasContext: ctx,
        viewport,
        transform: [resolution, 0, 0, resolution, 0, 0]
      }).promise

      this.pageRendered = true

      if (!this.$refs.textLayer) return
      this.$refs.textLayer.innerHTML = ''
      Object.assign(this.$refs.textLayer.style, {
        top: `${this.$refs.canvas.offsetTop}px`,
        left: `${this.$refs.canvas.offsetLeft}px`
      })

      const textContent = await page.getTextContent()

      if (!this.$refs.textLayer) return
      const layer = new pdfjs.TextLayer({
        textContentSource: textContent,
        container: this.$refs.textLayer,
        viewport
      })

      await layer.render()
      this.textDivs = layer.textDivs

      this.highlightMatches()
    } finally {
      this.pageRendering = false
    }
  }

  /**
   * Korostaa hakuosumat sivun tektistä.
   *
   * Tämä tapahtuu kävelemällä PDF.js:n luomia tekstielementtejä läpi ja luomalla
   * osumia vastaaviin kohtiin span-elementtejä, jotak tyylitellään halutulla tavalla.
   */
  highlightMatches() {
    this.textDivs.forEach(div => (div.innerHTML = div.innerText))
    this.matchSpans = this.matches.map(page => page.map(() => []))

    const matches = this.matches[this.currentPage - 1]
    const divs = this.textDivs

    const sortedMatches = sortBy(matches, ['start.item', 'start.index'])

    const boundaries = sortedMatches.flatMap(match => [match.start, match.end])

    const createHighlightSpan = (content: Node | string) => {
      const span = document.createElement('span')
      span.classList.add('match')
      span.append(content)
      return span
    }

    let divIndex = 0
    let boundaryIndex = 0
    let inMatch = false

    while (divIndex < divs.length && boundaryIndex < boundaries.length) {
      let boundary = boundaries[boundaryIndex]
      const div = divs[divIndex]
      div.normalize()

      if (boundary.item > divIndex) {
        if (inMatch) {
          const span = createHighlightSpan(div.innerText)
          this.matchSpans[this.currentPage - 1][Math.floor(boundaryIndex / 2)].push(span)
          div.innerHTML = ''
          div.append(span)
        }

        divIndex += 1
        continue
      }

      const nodes = [...div.childNodes]

      let i = 0
      let pos = 0
      let x = 0

      while (i < nodes.length && boundaryIndex < boundaries.length && x < 10) {
        x += 1

        boundary = boundaries[boundaryIndex]

        if (nodes[i].nodeType === Node.ELEMENT_NODE) {
          nodes.splice(i, 1, ...nodes[i].childNodes)
          continue
        }

        if (nodes[i].nodeType !== Node.TEXT_NODE) {
          i += 1
          continue
        }

        const node = nodes[i] as Text

        const crossedBoundary = boundary.item === divIndex && pos + node.data.length >= boundary.offset

        if (crossedBoundary) {
          const right = node.splitText(boundary.offset - pos)
          nodes.splice(i + 1, 0, right)
        }

        if (inMatch) {
          const span = createHighlightSpan(node.data)
          this.matchSpans[this.currentPage - 1][Math.floor(boundaryIndex / 2)].push(span)
          div.replaceChild(span, node)
        }

        if (crossedBoundary) {
          boundaryIndex += 1
          inMatch = !inMatch
        }

        pos += node.data.length
        i += 1
      }

      divIndex += 1
    }

    this.updateCurrentMatchHighlight()
  }

  searchKeydownHandler!: (evt: KeyboardEvent) => void

  created() {
    if (this.page !== undefined) {
      this.currentPage = this.page
    }
  }

  async mounted() {
    const url = new URL('pdfjs-dist/legacy/build/pdf.worker.mjs', import.meta.url)
    pdfjs.GlobalWorkerOptions.workerSrc = url.toString()
    this.document = await pdfjs.getDocument({ url: this.url }).promise

    this.searchKeydownHandler = (evt: KeyboardEvent) => {
      if (evt.ctrlKey && evt.key.toUpperCase() === 'F') {
        evt.preventDefault()
        this.searchOpen = true
      }
    }

    document.addEventListener('keydown', this.searchKeydownHandler)
  }

  beforeUnmount() {
    document.removeEventListener('keydown', this.searchKeydownHandler)
  }

  public show() {
    this.$refs.dialog.show()
  }

  public hide() {
    this.$refs.dialog.hide()
  }

  get signerInitials() {
    if (this.$store.user) {
      const palat = this.$store.user.attributes.full_name[0].split(' ')
      return `${palat[0][0]}${palat[1][0]}`
    }
    return ''
  }

  get filename() {
    return this.value.getDisplayValue().substring(0, this.value.getDisplayValue().lastIndexOf('.'))
  }

  private async onDownload() {
    if (!(await this.confirmDownload())) {
      return
    }

    const response = await fetch(this.url)
    const blob = await response.blob()
    const filename = this.title.match(/\.pdf$/i) ? this.title : this.title + '.pdf'
    downloadFile(blob, filename)
  }

  $tt(...[key, ...args]: Parameters<typeof this.$t>) {
    return this.$t(`PDFViewer.${key}`, ...args)
  }

  confirmDownload() {
    return new Promise<boolean>(resolve => {
      const dialog = this.$q.dialog({
        title: this.$t('AttachmentDisplay.downloadConfirmationDialog.title'),
        message: this.$t('AttachmentDisplay.downloadConfirmationDialog.message'),
        ok: this.$t('AttachmentDisplay.downloadConfirmationDialog.ok'),
        cancel: this.$t('AttachmentDisplay.downloadConfirmationDialog.cancel'),
        focus: 'cancel'
      })

      dialog.onOk(() => resolve(true))
      dialog.onCancel(() => resolve(false))
    })
  }

  closeSearch() {
    this.searchOpen = false
    this.search = ''
  }

  async startSigning() {
    const response = await apiFetch(apiPath + '/signature-image')
    const content = await response.arrayBuffer()
    this.signature = {
      image: `data:image/png;base64,${arrayBufferToBase64(content)}`,
      x: 0,
      y: 0
    }
  }

  async signPDF() {
    if (!this.signature) return

    // Ensin haetaan tiedosto ja puretaan salaus
    const blob = await fetchAndDecryptFile(this.tuple, this.value.attribute)

    const fileArrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => resolve(reader.result as ArrayBuffer)
      reader.onerror = reject

      reader.readAsArrayBuffer(blob)
    })

    // Muutetaan Base64:n siirtoa varten
    const fileB64 = arrayBufferToBase64(fileArrayBuffer)

    // Lähetetään tiedosto bäkkärille valmisteltavaksi
    const { digest } = await apiFetchJSON<{ digest: string; image: string }>(apiPath + '/prepareSignature', {
      method: 'POST',
      body: JSON.stringify({
        file: fileB64,
        position: {
          x: this.signature.x,
          y: this.signature.y
        },
        page: this.currentPage
      })
    })

    this.signer
      .sign(digest, 'nonRepudiation', 'rsa', 'digest', 'SHA256', 'cms')
      .then(response => {
        if (response.status === 'ok') {
          apiFetchJSON<{ signedFile: string }>(apiPath + '/sign', {
            method: 'POST',
            body: JSON.stringify({
              signature: response.signature,
              hstCert: response.chain![0]
            })
          }).then(async resp => {
            if (this.tuple.parent) {
              const signedAttachmentTuple: Tuple = await newTuple(new Tuple(Table.getTable('service_attachment'))).then(tuple => {
                tuple.resetOriginal()
                tuple.parent = this.tuple.parent
                return tuple
              })
              signedAttachmentTuple.setValue('file', resp.signedFile)
              const filename = `${this.filename}-${this.signerInitials}.pdf`
              signedAttachmentTuple.setValue('filename', filename)
              signedAttachmentTuple.setValue('service', this.tuple.parent.values.id.valueToString())

              const insertedFile = await insertFile(signedAttachmentTuple)
              this.tuple.parent.children.service_attachment.push(insertedFile)
              await updateTuple(this.tuple.parent, this.tuple.parent.getKeyString())

              this.reload()
              this.hide()

              this.$q.dialog({
                component: PDFViewer,
                componentProps: {
                  url: `data:application/pdf;base64,${resp.signedFile}`,
                  title: filename,
                  tuple: signedAttachmentTuple,
                  value: resp.signedFile,
                  signer: this.signer,
                  page: this.currentPage
                }
              })
            }
          })
        } else {
          console.log('SCS-pyyntö ei ok: ' + response.reasonText)
          this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstSignFailed'), response.reasonText))
        }
      })
      .catch(error => {
        console.error('SCS-pyyntö epäonnistui', error)
        this.notify(new Notification(NotificationLevel.WARNING, this.$t('RegistrationView.steps.authenticateWithHSTCard.hstSignFailed')))
      })
      .finally(() => {
        this.signInProcess = false
      })
  }

  tupleFetched(tuple: Tuple): Tuple {
    this.$store.$patch({ tuple })
    tuple.resetOriginal()
    return tuple
  }

  reload(): void {
    fetchTuple(this.getTableReq(), this.getKey()!).then(this.tupleFetched)
  }

  prevMatch() {
    if (this.activeMatch !== undefined) {
      this.activeMatch = Math.max(0, this.activeMatch - 1)
    } else {
      const index = this.matches
        .flatMap((matches, page) => matches.map(match => ({ page, match })))
        .reverse()
        .findIndex(({ page }) => page < this.currentPage)

      if (index > -1) {
        this.activeMatch = index
      }
    }
  }

  nextMatch() {
    if (this.activeMatch !== undefined) {
      this.activeMatch = Math.min(this.matchCount - 1, this.activeMatch + 1)
    } else {
      const index = this.matches.flatMap((matches, page) => matches.map(match => ({ page, match }))).findIndex(({ page }) => page >= this.currentPage)

      if (index > -1) {
        this.activeMatch = index
      }
    }
  }

  get disablePrevMatchButton() {
    return this.activeMatch === 0 || this.matchCount === 0 || (this.activeMatch === undefined && this.currentPage === 1)
  }

  get disableNextMatchButton() {
    return this.activeMatch === this.matchCount - 1 || this.matchCount === 0
  }

  get signatureStyles() {
    if (!this.signature) return {}

    return {
      top: `${this.signature.y * this.scale}px`,
      left: `${this.signature.x * this.scale}px`
    }
  }

  positionSignature(event: Event) {
    if (!this.signature) {
      return
    }

    const el = event.target as HTMLImageElement

    const { width, height } = el.getBoundingClientRect()
    const pageSize = this.$refs.canvas.getBoundingClientRect()

    Object.assign(this.signature, {
      x: (pageSize.width - width) / 2,
      y: (pageSize.height - height) / 2
    })
  }

  onSignatureMouseDown(evt: MouseEvent) {
    const el = evt.currentTarget as HTMLImageElement

    el.classList.add('dragging')
    el.classList.remove('pulse')

    const mouseStartX = evt.clientX
    const mouseStartY = evt.clientY
    const signatureStartX = el.offsetLeft
    const signatureStartY = el.offsetTop

    const moveListener = (evt: MouseEvent) => {
      if (!this.signature) return

      const signatureSize = el.getBoundingClientRect()
      const canvasSize = this.$refs.canvas.getBoundingClientRect()

      Object.assign(this.signature, {
        x: clamp(evt.clientX - mouseStartX + signatureStartX, 0, canvasSize.width - signatureSize.width) / this.scale,
        y: clamp(evt.clientY - mouseStartY + signatureStartY, 0, canvasSize.height - signatureSize.height) / this.scale
      })
    }

    document.addEventListener('mousemove', moveListener)
    document.addEventListener('mouseup', () => {
      el.classList.remove('dragging')
      document.removeEventListener('mousemove', moveListener)
    })
  }

  zoomHandler(evt: WheelEvent) {
    if (evt.ctrlKey) {
      this.scale = this.scale * (1 - clamp(evt.deltaY, -100, 100) * 0.005)
      evt.preventDefault()
    }
  }
}
</script>

<style lang="scss">
@import 'pdfjs-dist/legacy/web/pdf_viewer.css';
</style>

<style scoped lang="scss">
@use 'sass:math';

:deep(.q-dialog__inner:not(.q-dialog__inner--maximized)) .main-section {
  max-height: 80dvh;
}

.title-section,
.actions-section {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1) !important;
  z-index: 10;
}

.title-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;

  .scale {
    width: 5ch;
    text-align: center;
  }

  & > .q-icon:first-child {
    font-size: 1.5em;
    margin-right: 0.5em;
    color: #b30b00;
  }
}

.actions-section {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.main-section {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas: 'sidebar-left top-bar sidebar-right' 'sidebar-left content sidebar-right';

  .sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5;
    flex-shrink: 0;
    gap: 1em;
    background-color: #f8f9fa;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1) !important;

    &.left {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      width: 10em;
      padding: 1rem 1rem 0.5rem 1.3rem;
      grid-area: sidebar-left;
      overflow: scroll;

      .page-list-wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    &.right {
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      width: 25em;
      padding: 1rem 1rem 0.5rem 1.3rem;
      grid-area: sidebar-right;
      transition-duration: 200ms;

      .signature-buttons {
        display: flex;
        align-items: center;
        gap: 1em;

        .q-btn {
          background: white;
        }
      }

      p {
        margin-top: 1em;
      }
    }

    .page-thumbnail {
      cursor: pointer;
      position: relative;
    }

    .page-thumbnail.current-page .page-thumbnail-image {
      border: 1px solid #5e7388;
    }

    .page-thumbnail-image {
      width: 100%;
      aspect-ratio: 1 / 1.4;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #e4e4e4;
      border-radius: 0.25em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .page-thumbnail-label {
      opacity: 0.7;
      font-size: 0.8em;
      text-align: center;
      margin-top: 0.5em;
      font-weight: 500;
    }
  }

  .top-bar {
    grid-area: top-bar;
    background-color: white;
    z-index: 1;
    height: 4em;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    transition-duration: 200ms;
    transition-property: margin-top;
    font-size: 0.9em;

    .match-buttons {
      margin-left: 1em;

      .q-btn {
        margin: 0 0.5em;
      }
    }

    .q-input {
      font-size: 1em;
    }

    .no-matches,
    .match-count {
      margin-left: 0.5em;
    }

    .no-matches {
      color: #b30b00;
    }

    .match-count {
      color: gray;
    }
  }

  .content {
    overflow: scroll;
    padding: 1.5rem;
    grid-area: content;

    .page-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: min-content;
      min-height: 100%;

      .page {
        position: relative;
        overflow: hidden;

        canvas {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0.25rem;
          aspect-ratio: 1 / 1.4;
          width: 100%;
        }

        .page-spinner-container {
          position: absolute;
          inset: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .q-spinner {
            $size: 3em;
            width: #{$size};
            height: #{$size};
          }
        }
      }
    }
  }
}

.q-card {
  max-width: unset;
  position: relative;
  z-index: 0;
}

.actions-section {
  & > .q-btn {
    padding-left: 1em;
    padding-right: 1em;
  }

  .q-btn-group {
    & > .q-btn {
      margin: 0;
      padding: 0;

      min-width: 0 !important;

      &:nth-child(2) {
        width: 3.5em;
        text-align: center;
      }

      &:nth-child(1),
      &:nth-child(3) {
        width: 3em;
      }
    }
  }
}

.page-match-badge {
  $size: 1.2rem;

  position: absolute;
  top: #{-$size * 0.33};
  right: #{-$size * 0.33};
  background: #1a3144;
  height: #{$size};
  min-width: #{$size};
  border-radius: #{math.div($size, 2)};
  line-height: #{$size};
  text-align: center;
  color: white;
  font-size: 0.8em;
  padding: 0 0.4em;
}

.top-bar-leave-to,
.top-bar-enter-from {
  margin-top: -4em;
}

.sidebar-right-leave-to,
.sidebar-right-enter-from {
  margin-right: -20em;
}

:deep(.textLayer span.match) {
  position: relative;
  background: #1a3144;
  opacity: 0.3;

  &.active {
    background: yellow;
  }
}

@keyframes signature-pulse {
  0% {
    inset: -5px;
    border: 6px solid #2196f3;
    border-radius: 0px;
  }

  50% {
    inset: -50px;
    border-radius: 50px;
    border: 6px solid transparent;
  }

  100% {
    inset: -50px;
    border-radius: 50px;
    border: 6px solid transparent;
  }
}

.signature {
  position: absolute;
  cursor: grab;
  outline-offset: 0;

  transform: scale(calc(var(--scale-factor) * 0.33));
  transform-origin: 0px 0px;
  outline: 6px dashed rgba(0, 0, 0, 0.3);

  &.pulse {
    &::before {
      content: '';
      position: absolute;
      animation: signature-pulse ease-out 2s infinite;
    }

    &::after {
      content: '';
      position: absolute;
      animation: signature-pulse ease-out 2s infinite;
      animation-delay: 400ms;
    }
  }

  &.dragging {
    cursor: grabbing;
  }
}
</style>

<template>
  <div class="chat">
    <div class="header">Keskustelu</div>
    <div ref="messages" class="chat__messages">
      <div v-for="(message, i) in messages" :key="message.getUniqueId()" class="chat__message">
        <q-chat-message v-if="i === 0 || !isSameDay(messages[i - 1].values.sent.v, message.values.sent.v)" :label="formatDay(message.values.sent.v)" />
        <q-chat-message
          :name="getSenderName(message)"
          :text="[message.values.message.valueToString()]"
          :stamp="formatTime(message.values.sent.v)"
          :sent="isOwnMessage(message)"
          text-sanitize
          :bg-color="isOwnMessage(message) ? 'grey-4' : 'grey-2'"
        />
      </div>
    </div>
    <div class="chat__input">
      <q-input v-model="newMessage" outlined dense :placeholder="$t('SidebarChatView.writeMessage')" maxlength="5000" @keydown.enter="sendMessage">
        <template #append>
          <q-btn dense flat icon="send" @click="sendMessage" />
        </template>
      </q-input>
    </div>
  </div>
</template>

<script lang="ts">
import { Dayjs } from 'dayjs'
import { Component, Vue } from 'vue-facing-decorator'

import Table from '@/classes/Table'
import Tuple from '@/classes/Tuple'
import Imports from '@/utils/Imports'
import { apiPublicPath } from '@/utils/paths'

@Component({})
export default class SidebarChatView extends Vue {
  chatTable = Table.getTable('chat_message')
  messages: Tuple[] = []
  newMessage = ''
  websocket: WebSocket | null = null
  safeboxId = Imports.appStore.currentSafebox!.getKeyString()

  created(): void {
    this.websocket = new WebSocket(`wss://${window.location.host}${apiPublicPath}chat?safebox=` + this.safeboxId)
    this.websocket.addEventListener('message', async event => {
      const tupleJson = JSON.parse(event.data)
      const message = await Tuple.fromJSON(tupleJson, this.chatTable)
      // Jos viesti on jo olemassa, korvataan se
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].getUniqueId() === tupleJson.tmpKey) {
          this.messages[i] = message
          return
        }
      }
      this.messages.push(message)
      this.scrollToBottom()
    })
    // Merkataan tämän safeboxin viestit luetuiksi
    for (let i = 0; i < this.$store.user!.attributes.new_chat_messages.length; i++) {
      const row = this.$store.user!.attributes.new_chat_messages[i]
      if (row.split('|')[0] === this.safeboxId) {
        this.$store.user!.attributes.new_chat_messages[i] = this.safeboxId + '|0'
      }
    }
  }

  getSenderName(message: Tuple): string {
    if (this.isOwnMessage(message)) {
      return ''
    }
    if (message.values.sender.v == null) {
      return 'Poistettu käyttäjä'
    }
    return message.values.sender.getDisplayValue()
  }

  sendMessage(): void {
    if (this.newMessage.length === 0) return
    const newMessageTuple = new Tuple(this.chatTable)
    newMessageTuple.setValue('message', this.newMessage)
    newMessageTuple.setValue('safebox', this.safeboxId)
    newMessageTuple.toJSON().then(json => {
      this.websocket?.send(JSON.stringify(json))
    })
    // sender ja sent asetetaan vasta palvelimella, mutta asetetaan ne paikallista näyttämistä varten
    newMessageTuple.setValue('sender', this.$store.user!.name)
    newMessageTuple.setValue('sent', null)
    this.messages.push(newMessageTuple)
    this.scrollToBottom()
    this.newMessage = ''
  }

  formatTime(timestamp: Dayjs | null): string {
    // Timestamp tulee vasta palvelimelta, joten sen puuttuessa indikoidaan kesken olevaa lähetystä kolmella pisteellä
    if (!timestamp) return '...'
    return timestamp.format('HH:mm')
  }

  formatDay(timestamp: Dayjs | null): string {
    return timestamp?.format('dd DD.MM.YYYY') ?? ''
  }

  isSameDay(timestamp1: Dayjs | null, timestamp2: Dayjs | null): boolean {
    if (!timestamp1 || !timestamp2) return true
    return timestamp1.isSame(timestamp2, 'day')
  }

  scrollToBottom(): void {
    this.$nextTick(() => {
      const messages = this.$refs.messages as HTMLElement
      if (messages) {
        messages.scrollTop = messages.scrollHeight
      }
    })
  }

  isOwnMessage(message: Tuple): boolean {
    return message.values.sender.valueToString() === this.$store.user?.name
  }
}
</script>

<style scoped lang="scss">
.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  gap: 1em;
  font-family: 'Noto Sans';

  &__messages {
    display: flex;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    padding-right: 10px;
  }

  &__message {
    padding: 0 10px;
  }

  &__input {
    padding-top: 10px;
  }
}

.header {
  flex-shrink: 0;
  text-align: center;
  padding: 0 10px;
  overflow-y: auto;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}
</style>

import { sortBy } from 'lodash-es'
import { defineStore } from 'pinia'

import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import { TimelineObject } from '@/components/SidebarTimelineView.vue'
import { apiFetchJSON, getTabUrl as customGetTabUrl } from '@/utils/api-functions'
import Imports from '@/utils/Imports'
import { apiPath } from '@/utils/paths'
import { setSafeboxCookie } from '@/utils/safebox'

type Override<T1, T2> = Omit<T1, keyof T2> & T2

type TimelineResponse = {
  items: Override<TimelineObject, { tuple?: TupleJSON }>[]
  prevCursor: string | null
  nextCursor: string | null
}

export type CacheItem = {
  fetching: boolean
  value: Tuple | null
}

export type TimelineData = {
  items: TimelineObject[]
  prevCursor?: string | null
  nextCursor?: string | null
  fetching: boolean
}

export interface AppState {
  safeboxes: Tuple[]
  currentSafebox: Tuple | null
  labels: Tuple[]
  selectedLabels: Tuple[]
  cache: Record<string, CacheItem>
  timeline: Record<string, TimelineData>
}

/** Yksinkertaisetettu versio fetchTuples-funktiosta, joka ei välitä tuple-hierarkiasta muodostaessa URLia. */
export const fetchTuplesSimple = async (tabName: string, search?: Record<string, string>) => {
  const tab = Table.getTable(tabName)
  let url = `${apiPath}/${tab.name}`

  if (search) {
    url += `?${new URLSearchParams(search)}`
  }

  const response = await apiFetchJSON<TupleJSON[]>(url)

  return Promise.all(response.map(json => Tuple.fromJSON(json, tab)))
}

/** Etsii annetusta kokoelmasta annetun id:n mukaisen Tallelokeron */
function findSafebox(id: string, safeboxes: Tuple[]): Tuple | null {
  return safeboxes.find(a => a.values.id.v!.toString() === id) ?? null
}

function refreshLabels(state: AppState) {
  const currentSafeboxId = state.currentSafebox?.values.id.v
  if (currentSafeboxId) {
    apiFetchJSON<TupleJSON[]>(`${apiPath}/safebox/${currentSafeboxId}/label`).then(async labels => {
      state.labels = await Promise.all(labels.map(labelJSON => Tuple.fromJSON(labelJSON, Table.getTable('label'))))
    })
  }
}

export type FetchCachedOptions = {
  table: string
  key: string
}

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState => ({
    safeboxes: [],
    currentSafebox: null,
    labels: [],
    selectedLabels: [],
    cache: {},
    timeline: {}
  }),
  actions: {
    async fetch(tableName: string, key: string) {
      const cacheKey = `${tableName}|${key}`

      if (!this.cache[cacheKey]) {
        this.cache[cacheKey] = {
          fetching: true,
          value: null
        }

        const table = Table.getTable(tableName)
        const resultJson = await apiFetchJSON<TupleJSON>(customGetTabUrl(table, key))

        this.cache[cacheKey] = {
          fetching: false,
          value: await Tuple.fromJSON(resultJson, table)
        }
      }
    },
    async refreshSafeboxes() {
      if (!Imports.store.user) {
        return
      }

      const [safeboxes, accesses] = await Promise.all([
        fetchTuplesSimple('safebox'),
        fetchTuplesSimple('safebox_access', { username: Imports.store.user.name })
      ])

      this.safeboxes = sortBy(safeboxes, safebox => accesses.find(access => access.values.safebox.v === safebox.values.id.v)?.values?.order?.v ?? 0)

      refreshLabels(this)
    },
    clearSafebox() {
      this.currentSafebox = null
      setSafeboxCookie(null)
    },
    selectSafebox(id: string) {
      this.currentSafebox = findSafebox(id, this.safeboxes)
      this.selectedLabels = []
      setSafeboxCookie(id)
      refreshLabels(this)
    },
    refreshLabels() {
      refreshLabels(this)
    },
    selectLabel(label: Tuple) {
      const index = this.selectedLabels.findIndex((sl: Tuple) => sl.values.id.v === label.values.id.v)

      if (index === -1) {
        this.selectedLabels.push(label)
      }
    },
    unselectLabel(label: Tuple) {
      const index = this.selectedLabels.findIndex((sl: Tuple) => sl.values.id.v === label.values.id.v)

      if (index !== -1) {
        this.selectedLabels.splice(index, 1)
      }
    },
    clearLabels() {
      this.selectedLabels = []
    },
    logout() {
      this.selectedLabels = []
      this.currentSafebox = null
      this.labels = []
      this.safeboxes = []
    },
    async fetchTimeline(id?: string, more?: 'older' | 'newer') {
      if (!this.currentSafebox) {
        return
      }

      const url = 'api/timeline'
      let key = `${this.currentSafebox.values.id.v}`

      const query = new URLSearchParams()

      if (id) {
        query.set('parent', id)
        key += `-${id}`
      } else {
        query.set('safebox', this.currentSafebox.values.id.getDisplayValue())
      }

      let cursor: string | null | undefined = null

      if (more) {
        cursor = more === 'older' ? this.timeline[key]?.nextCursor : this.timeline[key]?.prevCursor
        const cursorName = more === 'older' ? 'before' : 'after'

        if (cursor) {
          query.set(cursorName, cursor)
        } else if (cursor === null && more !== 'newer') {
          return
        }
      }

      if (this.timeline[key]) {
        this.timeline[key].fetching = true
      } else {
        this.timeline[key] = {
          items: [],
          fetching: true
        }
      }

      const response = await apiFetchJSON<TimelineResponse>(`${url}?${query}`)

      const items: TimelineObject[] = await Promise.all(
        response.items.map(async item => {
          let tuple: Tuple | undefined

          if (item.tuple) {
            const tab = Table.getTable(item.tbl_name)
            tuple = await Tuple.fromJSON(item.tuple, tab)
          }

          return { ...item, tuple } as TimelineObject
        })
      )

      if (this.timeline[key] && more) {
        if (more === 'older') {
          if (this.timeline[key].nextCursor && this.timeline[key].nextCursor !== cursor) {
            return
          }

          Object.assign(this.timeline[key], {
            nextCursor: response.nextCursor,
            prevCursor: this.timeline[key].prevCursor ?? response.prevCursor,
            items: [...this.timeline[key].items, ...items]
          })
        } else {
          if (this.timeline[key].prevCursor && this.timeline[key].prevCursor !== cursor) {
            return
          }

          Object.assign(this.timeline[key], {
            prevCursor: response.prevCursor,
            nextCursor: this.timeline[key].nextCursor ?? response.nextCursor,
            items: [...items, ...this.timeline[key].items]
          })
        }

        this.timeline[key].fetching = false
      } else {
        this.timeline[key] = {
          ...response,
          items,
          fetching: false
        }
      }
    }
  },

  getters: {
    getTimeline: state => (id?: string) => {
      if (!state.currentSafebox) {
        return {
          items: [],
          more: false,
          fetching: false
        }
      }

      let key = `${state.currentSafebox.values.id.v}`

      if (id) {
        key += `-${id}`
      }

      const timeline = state.timeline[key]

      if (timeline) {
        return {
          items: timeline.items,
          more: !!timeline.nextCursor,
          fetching: timeline.fetching
        }
      } else {
        return {
          items: [],
          more: true,
          fetching: false
        }
      }
    }
  }
})
